import React from "react"
import "./FlexWrapper.css"
import { H1 } from "./Headline"

const FlexWrapper = props => {
  const { className, children, title, css } = props
  return (
    <>
      <div className={"FlexWrapper " + css}>
        {title ? <H1 className="Headline-h1-small">{title}</H1> : ""}
        <div className={"FlexWrapper-content " + className}>{children}</div>
      </div>
    </>
  )
}

FlexWrapper.defaultProps = {
  className: "",
  children: null,
  title: "",
  css: "",
}

export default FlexWrapper
