import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexWrapper from "../components/FlexWrapper"

import "./missions.css"

const missions = [
  "Trafikverket",
  "Försäkringskassan",
  "Myndigheten för samhällsskydd och beredskap",
  "Transportstyrelsen",
  "Jordbruksverket",
  "Livsmedelsverket",
  "Skogsstyrelsen",
  "Migrationsverket",
  "Länsstyrelsen i Jönköpings län",
  "Lantmäteriet",
  "Kriminalvården",
  "Kungliga tekniska högskolan",
  "Centrala studiestödsnämnden",
  "KPMG",
  "Linköpings universitet",
  "Polismyndigheten",
  "SJ",
  "RISE",
  "Rekryteringsmyndigheten",
  "NIRAS",
  "Humana",
  "Lantbrukarnas riksförbund",
  "PwC",
  "Alfakassan",
]

const ThirdPage = () => (
  <Layout>
    <SEO title="Uppdrag" />
    <p
      style={{
        paddingLeft: "8px",
        fontSize: "0.9em",
        maxWidth: "700px",
        margin: "0 auto",
        margin: "28px auto 20px",
      }}
    >
      Jag har under de senaste åren arbetat i uppdrag för bland annat följande
      statliga myndigheter, organisationer och företag:
    </p>
    <FlexWrapper
      className="FlexWrapper-content-no-margin"
      css="FlexWrapper-no-margin"
    >
      <ul style={{ marginBottom: 0, maxWidth: "600px" }}>
        {missions.slice(0, Math.floor(missions.length / 2)).map((item, i) => {
          return (
            <li
              key={i}
              style={{ marginBottom: "calc(1.45rem / 4)", fontSize: "0.8em" }}
              className="Missions-li"
            >
              {item}
            </li>
          )
        })}
      </ul>
      <ul>
        {missions
          .slice(Math.floor(missions.length / 2), missions.length)
          .map((item, i) => {
            return (
              <li
                key={i}
                style={{ marginBottom: "calc(1.45rem / 4)", fontSize: "0.8em" }}
                className="Missions-li"
              >
                {item}
              </li>
            )
          })}
      </ul>
    </FlexWrapper>
    <div className="Missions-footer">
      <h6>Ulf Lindegård - UL JURIDIK</h6>
    </div>
  </Layout>
)

export default ThirdPage
